import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
.light-theme {
    --primary-color: #bc96e6;
    --primary-color-light: #057FFF;
    --secondary-color: #ff7675;
    --background-dark-color: #F1F1F1;
    --background-dark-grey: #e4e4e4;
    --border-color: #cbced8;
    --background-light-color: #FFFFFF;
    --background-light-color-2: rgb(188, 150, 230, 0.25);
    --white-color: #151515;
    --font-light-color: #313131;
    --font-dark-color: #313131;
    --font-dark-color-2: #151515;
    --sidebar-dark-color: #E4E4E4;
    --scrollbar-bg-color: #383838;
    --scrollbar-thump-color: #6b6b6b;
    --scrollbar-track-color: #383838;
    --title-reflection-color: rgba(25,29,43,.1);
}
.dark-theme {
    --primary-color: #bc96e6;
    --primary-color-light: #057FFF;
    --secondary-color: #6c757d;
    --background-dark-color: #10121A;
    --background-dark-grey: #191D2B;
    --border-color: #2e344e;
    --background-light-color: #F1F1F1;
    --background-light-color-2: rgb(188, 150, 230, 0.25);
    --white-color: #FFF;
    --font-light-color: #a4acc4;
    --font-dark-color: #313131;
    --font-dark-color-2: #151515;
    --sidebar-dark-color: #191D2B;
    --scrollbar-bg-color: #383838;
    --scrollbar-thump-color: #6b6b6b;
    --scrollbar-track-color: #383838;
    --title-reflection-color: rgba(25,29,43,.8);
}
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
    }
    body{
        background-color: var(--background-dark-color);
        color: var(--font-light-color);
    }

body::-webkit-scrollbar{
    width: 9px;
    background-color: #383838;
}
body::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #6b6b6b;
}
body::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: #383838;
}

textarea{
    max-width: 100%;
}
    a {
        font-family: inherit;
        color: inherit;
        font-size: inherit;
    }

    

    h1 {
        font-size: 4rem;
        color: var(--white-color);
        span {
            font-size: 4rem;
        }
    }

    h2 {
        font-size: 3.5rem;
        color: var(--white-color);
        span {
            font-size: 3.5rem;
        }
    }

    h3 {
        font-size: 2.5rem;
        color: var(--white-color);
        span {
            font-size: 2.5rem;
        }
    }

    @media screen and (max-width: 502px){
        font-size: 3rem
    }

    span {
        color: var(--primary-color);
    }

    h6 {
    color: var(--white-color);
    font-size: 1.2rem;
    padding-bottom: .6rem;
}
//Utilities
.u-margin-bottom {
    margin-bottom: 4rem;
}
//Floting Toggler
.light-dark-mode {
    position: fixed;
    right: 0;
    background-color: var(--background-light-color-2);
    width: 3rem;
    height: 5rem;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      display: flex;
      align-items: center;
      font-size: 1.7rem;
      color: var(--white-color);
    }
  }
  //Nav Toggler
  .ham-burger-menu {
      position: absolute;
      right: 5%;
      top: 3%; 
      display: none;
      z-index: 15;
      svg{
          font-size: 3rem;
      }
    }
    .nav-toggle {
        transform: translateX(0);
        z-index: 20;
    }
  @media screen and (max-width:1200px){
    .ham-burger-menu{
        display: block;
    }
  }

`;
export default GlobalStyle;
